
  import { Component, Watch } from 'vue-property-decorator'
  import { GForm } from '@/components/forms/GForm'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import { plainToInstance } from 'class-transformer'
  import { ItemType, Reserve, SaleOrderItem } from '@/entities/sales'
  import { dateToLocal, fixDate, fixPrice, intervalFormat, isValidNumber, nameAlert } from '@/utils/general'
  import LinkedPerson from '@/components/forms/fields/LinkedPerson.vue'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'
  import { Deal, Lead } from '@/entities/crm'
  import { Payment } from '@/entities/finance'
  import dayjs from 'dayjs'
  import { Debounce } from '@/utils/decorators'
  import Agenda from '@/components/forms/fields/agenda/Agenda.vue'
  import AgendaField from '@/components/forms/fields/agenda/AgendaField.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import GCostField from '@/components/core/input/GCostField.vue'
  import AutoResume from '@/components/forms/AutoResume.vue'

@Component({
  components: { AutoResume, GCostField, GFiles, AgendaField, Agenda, GDatePicker, LinkedPerson, BaseForm },
  methods: { fixPrice },
  computed: {},
})
  export default class ReserveForm extends GForm {
  reserve: Reserve = plainToInstance(Reserve, {})
  lead: Lead = plainToInstance(Lead, {})
  deal: Deal = plainToInstance(Deal, {})

  errorDiscountPostFinancing = ''
  transferCost = 0
  process = null
  payments: Array<Payment> = []
  title = 'Reserva'
  idProcess = null
  isLoadingFile = false
  idProcessReserve = null
  photoProperties = {}
  showDetail = false
  minValue = null
  maxDays = null
  setPerson = false
  activity = null
  activities = []
  newAgenda = null
  rutError = ''

  formData = {
    client: null,
    discount: null,
    discountPostFinancing: null,
    schedulingDate: null,
    order: null,
    comment: null,
    timeStart: null,
    timeEnd: null,
    paymentMethod: null,
    backup: [],
  }

  schedules = []

  declare $refs: {
    form: HTMLFormElement
    fieldClient: LinkedPerson
  };

  metadata = {}
  metadataCollection = {}
  fields = {
    paymentMethods: {
      properties: {
        label: 'Formas de pago acordada',
        required: true,
        rules: this.fieldRequired,
        itemText: 'description',
        itemValue: 'id',
        returnObject: true,
      },
      items: [],
      rules: [],
    },
    client: {
      properties: {
        required: true,
        disabled: true,
        properties: {
          label: 'Cliente',
          rules: this.fieldRequired,
          itemText: 'formInfo',
          itemValue: 'id',
          returnObject: true,
        },
      },
      items: [],
      rules: [],
    },
    discount: {
      properties: {
        label: 'Descuento otorgado',
        required: true,
      },
      rules: this.fieldRequired,
    },
    date: {
      properties: {
        label: 'Fecha de entrega',
        required: true,
        rules: this.fieldRequired,
        maxMonths: 1,
        hint: '',
      },
    },
    order: {
      properties: {
        label: 'Abonos',
        required: true,
        readOnly: true,
        rules: this.fieldRequired,
        appendOuterIcon: 'mdi-plus-circle-outline',
      },
    },
    backup: {
      properties: {
        accept: null,
        multiple: false,
        fileTypeId: null,
        name: '',
      },
    },
  }

  async mounted () {
    await this.setFileProperties()
    await this.getInfo()

    const { uid, id, model } = this

    if ((!isNaN(uid) && (model?.toLowerCase() === 'reserve' || model === '')) || !isNaN(id)) {
      const reserveId = id || uid
      await this.getReserveInfo(reserveId)
    }

    if (!this.isBreadCrumbPresent(this.title)) {
      this.setFormCrumbs(this.getForm('Reserve', 'reserve'), 'Reserva', Boolean(!isNaN(uid)))
    }

    await this.setTheBackup()
    await this.setMetadata()
    const system = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { type: { name: { _eq: 'system' } } },
    })
    this.schedules = system?.[0]?.metadata?.schedule
  }

  get disabledWeekend () {
    const { schedules } = this

    const day = []

    if (schedules?.find(schedule => schedule?.day === 'Domingo')?.start === '') {
      day.push(0)
    }

    return day
  }

  async setFileProperties () {
    const idProcess = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'reserve' } },
    })

    this.minValue = idProcess[0].config.minValue
    this.maxDays = idProcess[0].config.maxDays
    this.idProcessReserve = idProcess[0]?.id
    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { _and: [{ process: { table_name: { _eq: 'reserve' } } }, { name: { _eq: 'signed_reservation' } }] },
    })

    const file = fileInfo[0]

    if (file) {
      Object.assign(this.fields.backup.properties, {
        accept: file.fileType.mimes,
        multiple: file.multiple,
        fileTypeId: file.fileType.id,
        name: file.name,
        label: file.description,
        required: file.required,
        icon: file.fileType.icon,
      })
    }
  }

  async getLeadInfo (parentModel, parentId, uid) {
    if (parentModel && parentId) {
      const { lead } = await this.getParentData(parentModel, parentId.toString())
      this.lead = lead
    }
    if (uid) {
      const leads = await this.fetchData({
        query: { name: 'find', model: 'Lead' },
        filter: { deals: { sale_orders: { reserve: { id: { _eq: uid } } } } },
        force: true,
      })

      this.lead = leads[0]
    }
  }

  async getInfo () {
    const { parentModel, parentId, id, model, uid } = this
    if ((!parentId && !parentModel) && (!model && !id && !uid)) return
    await this.getLeadInfo(parentModel, parentId, uid)

    const lead = this.lead
    const discount = lead?.sale?.order?.discount
    const discountPostFinancing = lead?.sale?.order?.discountPostFinancing

    this.setLeadInfo(lead, discount, discountPostFinancing)
    const resp = await this.fetchData({
      query: { name: 'find', model: 'ActivityType' },
      filter: { name: { _eq: 'delivery' } },
    })

    this.activity = resp[0]

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'payment' } },
    })

    this.idProcess = process[0].id

    const fileInfo = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { _and: [{ process: { table_name: { _eq: 'payment' } } }, { file_type: { name: { _eq: 'photo' } } }] },
    })

    Object.assign(this.photoProperties, {
      accept: fileInfo[0].fileType.mimes,
      multiple: fileInfo[0].multiple,
      fileTypeId: fileInfo[0].fileType.id,
      name: fileInfo[0].name,
      label: fileInfo[0].description,
      required: fileInfo[0].required,
      icon: fileInfo[0].fileType.icon,
    })
    this.fields.paymentMethods.items = await this.fetchData({
      query: { name: 'find', model: 'PaymentType' },
    })

    if (lead?.sale?.stock?.id) {
      this.transferCost = await this.calculateTransfer(lead.sale.stock?.id)
    }
    await this.setDetails()
  }

  setLeadInfo (lead, discount, discountPostFinancing) {
    const { client } = lead as Lead
    if (discount?.value) {
      this.formData.discount = discount?.value
    }
    if (discountPostFinancing?.value) {
      this.formData.discountPostFinancing = discountPostFinancing.value
    }
    this.formData.client = client
    this.fields.client.items = [client]
  }

  async getReserveInfo (id) {
    const reserve = await this.fetchData({
      query: { name: 'fetch', model: 'Reserve', params: { id } },
      force: true,
    })
    this.reserve = reserve

    const { saleOrder: { deal: { lead: { id: leadId }, stock }, discount, discountPostFinancing } } = reserve
    const { lead } = await this.getParentData('Lead', leadId.toString())
    this.setLeadInfo(lead, discount, discountPostFinancing)
    this.lead = lead as Lead
    await this.setFormInfo(reserve)
    const backup = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: reserve.id } }, { parameter: { process: { id: { _eq: this.idProcessReserve } } } }, { parameter: { name: { _eq: 'signed_reservation' } } }] },
      force: true,
    })
    this.formData.backup = backup || []
    this.transferCost = await this.calculateTransfer(stock?.id)

    const projections = await this.fetchData({
      query: { name: 'find', model: 'ProjectionPayment' },
      filter: { id_sale_order: { _eq: reserve.saleOrder.id } },
      force: true,
    })

    this.formData.paymentMethod = projections.map(p => p.payment)

    await this.setDetails()
  }

  async setFormInfo (reserve) {
    const { saleOrder: { deliveryDate }, id, comment } = reserve

    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'reserve' } },
    })

    this.process = process[0]
    this.formData.order = await this.fetchData({
      query: { name: 'find', model: 'Payment' },
      filter: {
        _and: [
          { id_process_record: { _eq: id } },
          { id_process: { _eq: process?.[0].id } },
          { _or: [{ id_closing_reason: { _is_null: true } }, { closing_reason: { type: { name: { _neq: 'canceled' } } } }] },
        ],
      },
      force: true,
    })

    this.formData.schedulingDate = dateToLocal(deliveryDate).format('DD/MM/YYYY')
    this.formData.timeStart = dateToLocal(deliveryDate).format('HH:mm')
    this.formData.timeEnd = dateToLocal(deliveryDate).add(1, 'hour').format('HH:mm')
    this.formData.comment = comment
  }

  async setMetadata () {
    const { metadata: metadataReserve } = this.getForm('Reserve', 'reserve')

    this.metadataCollection = metadataReserve
    await this.setDetails()
  }

  async handleBackupPayment (backup) {
    const payment = 'payment' in backup && backup.payment

    const paymentForm = 'paymentForm' in backup && backup.paymentForm
    if (paymentForm) {
      const pay = this.setPayment(paymentForm)

      this.payments.push(pay)

      if (!this.formData.order?.length) {
        this.formData.order = this.payments
      } else {
        this.formData.order.push(pay)
      }
      this.backup.paymentForm = null
      this.backup.payment = null
    }

    if (payment) {
      const pos = this.formData.order?.findIndex(order => order.id === payment.id)

      if (pos >= 0) {
        this.formData.order.splice(pos, 1, payment)
      }
    }

    await this.setPayments()
  }

  setPayment (paymentForm) {
    const {
      paymentType,
      payer,
      amount,
      bank,
      reference,
      paymentDate,
      comment,
      paymentBackup,
      expirationDate,
      quotas,
      account,
    } = paymentForm

    const pay = plainToInstance(Payment, {})

    pay.type = paymentType
    pay.payer = payer
    pay.reference = reference
    pay.amount = amount
    pay.issuingBank = bank
    pay.date = paymentDate
    pay.comment = comment
    pay.paymentBackup = paymentBackup
    pay.expiration = expirationDate
    pay.paymentCount = quotas
    pay.financialAccount = account

    return pay
  }

  async setPayments () {
    const { reserve } = this

    if (!reserve?.id) return
    this.formData.order = await this.fetchData({
      query: { name: 'find', model: 'Payment' },
      filter: {
        _and: [
          { id_process_record: { _eq: this.reserve.id } },
          { id_process: { _eq: this.process?.id } },
          { _or: [{ id_closing_reason: { _is_null: true } }, { closing_reason: { type: { name: { _neq: 'canceled' } } } }] },
        ],
      },
      force: true,
    })
  }

  closeForm () {
    const role = this.$route.path.replace('/form/', '').split('_')[0]
    this.$router.push({ path: `/datatable/${role}/${role}_reservations` }).catch(() => {
    })
  }

  async setTheBackup () {
    const { backup } = this
    if (!backup) return

    if (backup && 'reserveForm' in backup && backup.reserveForm) {
      this.formData = backup.reserveForm
    }

    await this.handleBackupPayment(backup)

    const resp = await this.fetchData({
      query: { name: 'find', model: 'ActivityType' },
      filter: { name: { _eq: 'delivery' } },
    })

    this.activity = resp[0]
  }

  async goPerson () {
    const { formData, reserve } = this
    this.saveBackup()
    const idReserve = reserve?.id ? reserve?.id.toString() : 'created'
    const idPerson = formData.client?.id
    await this.$router.push({ name: 'generic-person-nested', params: { model: 'Reserve', uid: idReserve, id: idPerson } })
  }

  async goToOrder () {
    const { deal } = this
    if (this.backup?.paymentForm) {
      this.backup.paymentForm = null
      this.backup.client = null
    }
    this.saveBackup()

    const idDeal = deal?.id ? deal?.id.toString() : 'create'
    await this.$router.push({
      name: 'generic-payment',
      params: { parentModel: 'Deal', parentId: idDeal, id: 'create' },
    })
  }

  saveBackup () {
    const { backup, formData, lead, deal, reserve } = this
    if (backup) {
      backup.lead = lead
      backup.deal = deal
      backup.reserveForm = formData
      backup.paymentForm = null
      backup.client = null
      backup.reserve = reserve
      this.setBackup(backup)
    } else {
      this.setBackup({ reserveForm: formData, lead, deal, reserve, client: null })
    }
  }

  async send () {
    const client = this.$refs.fieldClient
    const errorDiscount = this.errorDiscountPostFinancing?.length
    if (!this.$refs.form.validate() || !client.$refs.form.validate() || this.paymentError?.length || errorDiscount || this.disabledReserve) {
      return
    }
    this.loadingForm = true
    this.saveBackup()
    const { formData, reserve, lead } = this
    const startDate = formData?.schedulingDate + ' ' + formData.timeStart
    const endDate = formData?.schedulingDate + ' ' + formData.timeEnd
    const date = dayjs(startDate, 'DD/MM/YYYY HH:mm')
    let id
    if (!reserve.id) {
      const newReserve = await this.insertReserve(date, startDate, endDate)
      id = newReserve.id
      await this.updateStock()
      await this.insertUpdatePaymentType(newReserve)
    } else {
      await this.updateReserve(date, startDate, endDate)
      await this.insertUpdatePaymentType(reserve)
      id = reserve.id
    }

    await this.handleFileType(formData.backup, { properties: this.fields.backup.properties }, this.idProcessReserve, id)
    await this.updateDiscount(formData, lead)
    await this.handleTransfer(this)
    await this.$router.push({ path: '/datatable/staff/staff_reservations' }).catch(() => {
    })
  }

  async updateStock () {
    const { lead: { sale: { stock: { id } } } } = this
    const status = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ status: { name: { _eq: 'reservation_process' } } }, { process: { table_name: { _eq: 'stock' } } }] },
    })

    const fields = {
      id,
      id_process_status: status[0].id,
    }
    await this.pushData({ model: 'Stock', fields })
    await this.publishStock(id)
  }

  async addOrUpdateDiscountPostFinancing () {
    const { deal, formData } = this
    if (!isValidNumber(formData.discountPostFinancing)) return

    const pos = deal.saleOrders[0].saleOrderItems.findIndex(item => item.type.name === 'post_financing_discount')

    if (!pos || pos < 1) {
      const type = await this.fetchData({
        query: { name: 'find', model: 'ItemType' },
        filter: { name: { _eq: 'post_financing_discount' } },
      })
      await this.pushData({
        model: 'SaleOrderItem',
        fields: {
          amount: formData.discountPostFinancing,
          id_sale_order: deal?.saleOrders?.[0]?.id,
          quantity: 1,
          id_item_type: type[0]?.id,
        },
      })
    } else {
      const discountId = deal.saleOrders[0].saleOrderItems[pos].id
      await this.pushData({
        model: 'SaleOrderItem',
        fields: {
          id: discountId,
          amount: formData.discountPostFinancing,
        },
      })
    }
  }

  async updateDiscount (data, lead) {
    const { discountIsPending } = this
    const { sale: { order } } = lead

    if (discountIsPending) {
      await this.addOrUpdateDiscountPostFinancing()
      return
    }

    if (!isValidNumber(data.discount)) {
      return
    }

    if (!order?.discount) {
      const fields = {
        id_item_type: 2,
        quantity: 1,
        amount: data.discount,
        id_sale_order: order.id,
      }
      await this.pushData({ model: 'SaleOrderItem', fields })
    } else if (order.discount !== data.discount) {
      let fields
      const discount = order.saleOrderItems.filter(item => item.isDiscount)
      if (discount?.length && discount[0]?.id) {
        fields = {
          id: discount[0].id,
          amount: data.discount,
        }
      } else {
        fields = {
          id_item_type: 2,
          quantity: 1,
          amount: data.discount,
          id_sale_order: order.id,
        }
      }
      await this.pushData({ model: 'SaleOrderItem', fields })
    }
  }

  async updateReserve (date, startDate, endDate) {
    const { reserve, formData, lead: { sale: { order }, activity } } = this

    if (formData.comment !== reserve.comment) {
      const fields = {
        id: reserve.id,
        comment: formData.comment,
      }
      await this.pushData({ model: 'Reserve', fields })
    }

    const paymentsClosed = formData.order.every(o => o.closingReason)
    const allowToConfirm = formData?.backup?.length && (reserve.status.isActive || reserve.status.isToUpdating) && paymentsClosed
    const paymentsToUpdate = formData.order.some(o => o.status.isToUpdating || o.status.isPending)

    if (allowToConfirm) {
      const status = await this.fetchData({
        query: { name: 'find', model: 'ProcessStatus' },
        filter: { _and: [{ process: { table_name: { _eq: 'reserve' } } }, { status: { name: { _eq: 'to_confirm' } } }] },
      })

      const fields = {
        id: reserve.id,
        id_process_status: status[0].id,
      }

      return this.pushData({ model: 'Reserve', fields })
    } else if (paymentsToUpdate) {
      const status = await this.fetchData({
        query: { name: 'find', model: 'ProcessStatus' },
        filter: { _and: [{ process: { table_name: { _eq: 'reserve' } } }, { status: { name: { _eq: 'active' } } }] },
      })

      const fields = {
        id: reserve.id,
        id_process_status: status[0].id,
      }

      return this.pushData({ model: 'Reserve', fields })
    }

    if (!dayjs(formData.schedulingDate).isSame(dateToLocal(order.deliveryDate))) {
      const fields = {
        id: order.id,
        delivery_date: date,
      }
      await this.pushData({ model: 'SaleOrder', fields })

      const task = {
        id: activity.id,
        scheduling_date: date,
        duration: intervalFormat(fixDate(startDate), fixDate(endDate)),
      }

      await this.pushData({ model: 'LeadActivity', fields: task })
    }
    const orders = formData.order?.filter(o => !o.id)

    if (orders.length) {
      await this.createPayment(reserve)
    }
    await this.insertUpdatePaymentType(reserve)
  }

  async handleTransfer (viewData) {
    const { transferCost, lead: { sale: { order } } } = viewData

    const saleOrder = await this.fetchData({
      query: { name: 'fetch', model: 'SaleOrder', params: { id: order.id } },
    })

    const exist = saleOrder?.saleOrderItems?.find(item => item.type.name === 'transfer')

    if (exist) {
      await this.pushData({
        model: 'SaleOrderItem',
        fields: {
          id: exist.id,
          amount: transferCost,
        },
      })
      return
    }

    const transfer = (await this.fetchData({
      query: { name: 'find', model: 'ItemType' },
      filter: { name: { _eq: 'transfer' } },
    }))[0]

    const fields = {
      id_item_type: transfer?.id,
      quantity: 1,
      amount: transferCost,
      id_sale_order: saleOrder?.id,
    }

    await this.pushData({
      model: 'SaleOrderItem',
      fields,
    })

    const existProduct = saleOrder?.saleOrderItems?.find(item => item.type.name === 'product')
    if (!existProduct) {
      const product = (await this.fetchData({
      query: { name: 'find', model: 'ItemType' },
      filter: { name: { _eq: 'product' } },
    }))[0]

    const productFields = {
      id_item_type: product?.id,
      quantity: 1,
      amount: this.priceList,
      id_sale_order: saleOrder?.id,
    }

    await this.pushData({
      model: 'SaleOrderItem',
      fields: productFields,
    })
    }
  }

  async insertReserve (date, startDate, endDate) {
    const now = dayjs()
    const reserve = await this.createReserve(date, startDate, endDate, now)
    await this.createPayment(reserve)

    return reserve
  }

  async insertUpdatePaymentType (reserve) {
    const { formData: { paymentMethod } } = this

    const payments = await this.fetchData({
      query: { name: 'find', model: 'ProjectionPayment' },
      filter: { id_sale_order: { _eq: reserve.saleOrder.id } },
      force: true,
    })

    await Promise.all(paymentMethod.map(async item => {
      const exist = payments?.find(p => p.payment.id === item.id)

      const fields = {
        id_sale_order: reserve.saleOrder.id,
        id_payment_type: item.id,
      }
      if (!exist) {
        await this.pushData({
          model: 'ProjectionPayment',
          fields,
        })
      }
    }))
    const deletePayments = payments.filter(p => !paymentMethod.map(pm => pm.id).includes(p.payment.id))

    await Promise.all(deletePayments.map(async item => {
      await this.removeData({ model: 'ProjectionPayment', fields: { id: item.id } })
    }))
  }

  async createReserve (date, startDate, endDate, now) {
    const { lead: { sale: { order } }, maxDays, lead, activity, formData } = this

    const status = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'reserve' } } }, { status: { name: { _eq: 'active' } } }] },
    })

    const fields = {
      id_process_status: status[0].id,
      expiration: now.add(maxDays, 'days'),
      id_sale_order: order.id,
      comment: formData.comment,
    }

    const reserve = this.pushData({ model: 'Reserve', fields })

    if (reserve) {
      const leadActivityStatus = await this.fetchData({
        query: { name: 'find', model: 'ProcessStatus' },
        filter: { _and: [{ process: { table_name: { _eq: 'lead_activity' } } }, { status: { name: { _eq: 'pending' } } }] },
      })
      const task = {
        id_activity_type: activity.id,
        id_lead: lead?.id,
        id_process_status: leadActivityStatus[0].id,
        scheduling_date: date,
        duration: intervalFormat(fixDate(startDate), fixDate(endDate)),
      }

      await this.pushData({ model: 'LeadActivity', fields: task })

      const saleFields = {
        id: order.id,
        delivery_date: date,
        duration: intervalFormat(fixDate(startDate), fixDate(endDate)),
      }
      await this.pushData({ model: 'SaleOrder', fields: saleFields })
    }

    return reserve
  }

  async createPayment (process) {
    const { formData, deal, photoProperties, idProcess } = this

    const status = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ default: { _eq: true } }, { process: { table_name: { _eq: 'payment' } } }] },
    })

    const processValue = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'reserve' } },
    })

    await Promise.all(formData.order.filter(o => !o.id).map(async item => {
      const fields = {
        id_payment_type: item.type.id,
        id_process: processValue[0].id,
        id_process_record: process.id,
        id_process_status: status[0].id,
        amount: item.amount,
        reference: item.reference,
        date: fixDate(item.date),
        id_payer: item.payer.id,
        id_issuing_bank: item?.issuingBank?.id,
        comment: item.comment,
        id_deal: deal.id,
        expiration: fixDate(item.expirationDate),
        payment_count: item.quotas,
        id_financial_account: item.financialAccount?.id,
      }
      if (!fields?.reference) {
        delete fields.reference
      }
      if (!fields?.id_issuing_bank) {
        delete fields.id_issuing_bank
      }
      if (!fields?.expiration) {
        delete fields.expiration
      }
      if (!fields?.payment_count) {
        delete fields.payment_count
      }

      const pay = await this.pushData({ model: 'Payment', fields })
      if (item.paymentBackup.length) {
        await this.handleFileType(item.paymentBackup, { properties: photoProperties }, idProcess, pay.id)
      }
    }))
  }

  async setDetails () {
    const { metadataCollection, lead, formData: { discount }, transferCost, totalAmount, reserve } = this

    const deal = lead.deals?.find(deal => deal.isSale)
    if (!deal) return

    const pos = deal.saleOrders[0].saleOrderItems.findIndex(item => item.type.name === 'discount')

    if (!pos || pos < 0) {
      deal.saleOrders[0].saleOrderItems.push(plainToInstance(SaleOrderItem, {
        amount: discount,
        type: plainToInstance(ItemType, { id: 2, name: 'discount' }),
      }))
    } else {
      deal.saleOrders[0].saleOrderItems[pos].amount = discount
    }

    deal.lead = lead
    deal.lead.sale.stock.transferCost = transferCost
    deal.lead.sale.totalAmount = totalAmount
    deal.stock.transferCost = transferCost
    deal.auto = deal.lead.sale.auto
    deal.stock.transferCost = transferCost
    deal.totalAmount = totalAmount
    deal.order._discount = discount
    deal.order.reserve = reserve
    this.deal = deal

    if (!reserve?.saleOrder) {
      reserve.saleOrder = deal.order
    }
    reserve.saleOrder.deal = deal
    this.metadata = {
      data: reserve,
      metadata: metadataCollection,
    }

    this.showDetail = Boolean(deal.id)
  }

  async getActivitiesByLead () {
    const { idEmployee, formData: { date } } = this

    const filter = {
      _and: [
        {
          lead: {
            id_executive: {
              _eq: idEmployee,
            },
          },
        }, {
          id_closing_reason: {
            _is_null: true,
          },
        }, {
          activity_type: { name: { _neq: 'inspection' } },
        }, {
          scheduling_date: {
            _gte: dayjs(fixDate(date)).startOf('day'),
            _lte: dayjs(fixDate(date)).endOf('day'),
          },
        }],
    }

    return this.fetchData({
      query: {
        name: 'find',
        model: 'LeadActivity',
      },
      filter,
      force: true,
    })
  }

  get discount () {
    const { lead: { sale } } = this
    if (!sale) return 0
    const { stock } = sale
    return stock?.price?.bonus?.amount || stock?.price?.margin || 0
  }

  get bindClient () {
    const { fields: { client } } = this

    if (!client) return {}

    return { ...client.properties, items: client.items }
  }

  get paymentError () {
    const { minValue, formData: { order } } = this
    if (!order) return ''
    return this.paymentValid(order) ? '' : `La suma de los pagos debe ser mayor ${fixPrice(minValue)}`
  }

  get discountIsPending () {
    const { lead: { sale } } = this
    if (!sale) return false
    const { financial } = sale
    if (!financial) return false
    const { evaluation } = financial

    return Boolean(evaluation)
  }

  paymentValid (payments) {
    const { minValue } = this
    const suma: number = payments.reduce((total, item) => {
      return total + Number(item.amount)
    }, 0)

    return suma >= minValue
  }

  onTimeRangeSelected (range: { start: string; end: string }) {
    if (!range?.start && !range?.end) return
    this.formData.timeStart = range?.start
    this.formData.timeEnd = range?.end
  }

  get typeChange () {
    const { activity, formData: { schedulingDate } } = this

    return {
      activity,
      date: schedulingDate,
    }
  }

  @Watch('typeChange', { immediate: true, deep: true })
  @Debounce(1000)
  async onDateChange ({ activity, date }) {
    if (!activity || !date) return null
    const { lead } = this

    this.activities = await this.getActivitiesByLead()
    this.newAgenda = { title: activity, date, allowSelect: true, lead }

    await this.setDetails()
  }

  @Watch('formData.discount', { immediate: true })
  @Debounce(100)
  onDiscountChange () {
    this.setDetails()
  }

  async editPayment (item) {
    const { deal } = this
    this.saveBackup()

    const idDeal = deal?.id ? deal?.id.toString() : 'create'
    await this.$router.push({
      name: 'generic-payment',
      params: { parentModel: 'Deal', parentId: idDeal, uid: item.id },
    })
  }

  get discountAuthorized () {
    const { reserve: { saleOrder } } = this

    if (!saleOrder) return 0

    return (saleOrder?.discount?.value || 0)
  }

  get priceList () {
    const { deal } = this

    if (!deal) return 0
    return deal?.stock?.price?.amount || 0
  }

  get totalAmount () {
    const { discountAuthorized: discount, priceList, transferCost } = this

    return priceList + transferCost - discount
  }

  get discountPostFinancingChange () {
    const { formData: { discountPostFinancing, discount }, deal } = this

    return {
      discountPostFinancing,
      discount,
      deal,
    }
  }

  loadingFile (flag) {
    this.isLoadingFile = flag
  }

  @Watch('discountPostFinancingChange', { immediate: true, deep: true })
  @Debounce(500)
  onDiscountFinancingChange (val) {
    const max = val?.deal?.lead?.sale?.stock?.price?.bonuses?.[0]?.amount || val?.deal?.lead?.sale?.stock?.currentPrice?.margin

    const discount = Number(val.discountPostFinancing) + Number(val.discount)

    if (discount > Number(max)) {
      this.errorDiscountPostFinancing = `El descuento no puede ser mayor al autorizado ${fixPrice(max)}`
    } else {
      this.errorDiscountPostFinancing = ''
    }

    this.setDetails()
  }

  @Watch('formData.client', { immediate: true, deep: true })
  async onClientChange (val) {
    if (val?.id) {
      const person = await this.fetchData({
        query: { name: 'fetch', model: 'Person', params: { id: val.id } },
        force: true,
      })
      if (!this.setPerson) {
        this.setPerson = true
        this.formData.client = person
      }

      const errors = nameAlert(person)
      if (!person?.uid) {
        errors.push('Rut es obligatorio para crear la reserva')
        this.rutError = errors.join(', ')
      } else if (errors?.length) {
        this.rutError = errors.join(', ')
      } else {
        this.rutError = ''
      }
    }
  }

  getIcon (item) {
    if (item?.closingReason?.name === 'successful') {
      return 'mdi-eye'
    }
    return 'mdi-pencil-outline'
  }

  get disabledReserve () {
    const { reserve, isSupervisor } = this

    return Boolean(reserve?.closingReason?.id) || isSupervisor
  }
  }
